/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";

const MarkdownTemplate = ({ data: { markdownRemark } }) => {
  const { html } = markdownRemark;
  return (
    <Layout>
      <div
        className="markdown-body min-h-screen max-w-[1120px] mx-auto px-16 md:px-0 pt-12 pb-16 whitespace-normal text-night-rider"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
export default MarkdownTemplate;
